import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function TabContratos() {
  const { role, changeRolePermission } = useRoleContext()
  const { contratos } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Ver"
          description={
            <div>Define se o usuário possui permissão para ver contratos</div>
          }
          component={
            <Switch
              name="contratos.read"
              checked={contratos?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Criar"
          description={
            <div>Define se o usuário possui permissão para criar contratos</div>
          }
          component={
            <Switch
              name="contratos.create"
              checked={contratos?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Alterar"
          description={
            <div>
              Define se o usuário possui permissão para alterar contratos
            </div>
          }
          component={
            <Switch
              name="contratos.update"
              checked={contratos?.update || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Deletar"
          description={
            <div>
              Define se o usuário possui permissão para deletar contratos
            </div>
          }
          component={
            <Switch
              name="contratos.delete"
              checked={contratos?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Acessar contratos protegidas"
          description="Define se o usuário tem permissão para acessar as contratos protegidas"
          component={
            <Switch
              name="contratos.protegidas"
              checked={Boolean(contratos?.protegidas)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa) || !Boolean(contratos?.protegidas)}
            />
          }
        />
      </Stack>
    </Box>
  )
}
