// import { FallbackProps } from 'react-error-boundary'
import { Alert, Button, Collapse, Stack, Typography } from '@mui/material'
import { Replay } from '@mui/icons-material'
import errorImage from 'assets/images/error.svg'
import { useState } from 'react'

interface FallbackProps {
  error: Error
  componentStack: string
  resetError(): void
}

export default function Fallback(props: FallbackProps) {
  const [displayDatails, showDetails] = useState<Boolean>(false)

  const { error, resetError, componentStack } = props

  return (
    <Stack sx={{ alignItems: 'center', mt: 8, padding: 4, gap: 4 }}>
      <img src={errorImage} alt="Imagem de erro" />

      <Stack sx={{ textAlign: 'center', gap: 2 }}>
        <Typography variant="h1">Oops! Algo deu errado</Typography>

        <Typography variant="body2">
          O erro abaixo ocorreu durante o processamento da sua requisição.
        </Typography>

        <Typography variant="body2">
          Não se preocupe! Quando isso acontece a nossa equipe é notificada para
          analisar e corrigir o problema.
        </Typography>

        <Alert
          icon={false}
          severity="error"
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => showDetails(!displayDatails)}
            >
              {displayDatails ? 'Esconder Detalhes' : 'Ver Detalhes'}
            </Button>
          }
        >
          {error.message}
        </Alert>

        <Collapse in={Boolean(displayDatails)}>
          <Alert severity="warning" icon={false}>
            {componentStack}
          </Alert>
        </Collapse>
      </Stack>

      <Button
        variant="contained"
        onClick={() => resetError()}
        startIcon={<Replay />}
      >
        Tentar novamente
      </Button>
    </Stack>
  )
}
