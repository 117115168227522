import { SyntheticEvent, useState } from 'react'

export default function useTabs() {
  const [value, setValue] = useState(0)
  function handleChange(_: SyntheticEvent, newValue: number) {
    setValue(newValue)
  }

  return { value, handleChange, setValue }
}
