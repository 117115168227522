import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  styled,
} from '@mui/material'

type TextFieldProps = {} & MuiTextFieldProps

const MuiTextAreaPrpops = styled(MuiTextField)((props) => {
  const { multiline } = props
  return {
    ...(multiline && {
      '& .MuiInputBase-root': { height: 'auto' },
    }),
  }
})

export default function TextField(props: TextFieldProps) {
  return <MuiTextAreaPrpops {...props} />
}
