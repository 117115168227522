import { Container, Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import useRole, { RoleModel } from 'hooks/queries/useRole'
import useTabs from 'hooks/useTabs'
import { useNavigate, useParams } from 'react-router-dom'
import TabVisibilidade from './components/TabVisibilidade'
import TabUsuarios from './components/TabUsuarios'
import TabEmpresas from './components/TabEmpresas'
import RoleProvider from 'components/Contexts/RoleContext'

import TabAplicativos from './components/TabAplicativos'
import TabRoles from './components/TabRoles'
import { Content, PageTitle } from '@data-c/ui'
import TabContratos from './components/TabContratos'

const tabMaps = [
  {
    label: 'Visibilidade',
    component: <TabVisibilidade />,
    visible: true,
  },
  {
    label: 'Aplicativos',
    component: <TabAplicativos />,
    visible: true,
  },
  {
    label: 'Empresas',
    component: <TabEmpresas />,
    visible: true,
  },
  {
    label: 'Usuários',
    component: <TabUsuarios />,
    visible: true,
  },
  {
    label: 'Contratos',
    component: <TabContratos />,
    visible: true,
  },
  {
    label: 'Permissões',
    component: <TabRoles />,
    visible: true,
  },
]

export default function Role() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { useQueryByUuid } = useRole()
  const { handleChange, value } = useTabs()
  const { data, isLoading } = useQueryByUuid<RoleModel>(id || '')
  console.log(data)
  if (isLoading) {
    return '...Carregando'
  }

  return (
    <Container>
      <PageTitle
        onBack={() => {
          navigate(-1)
        }}
        title={`Permissão - ${data?.nome || ''}`}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          sx={
            {
              // position: 'absolute',
              // width: '100%',
              // bgcolor: 'background.paper',
            }
          }
          scrollButtons={true}
          // allowScrollButtonsMobile
        >
          {tabMaps.map(
            (t, index) =>
              t.visible && <Tab key={t.label} label={t.label} value={index} />,
          )}
        </Tabs>
      </PageTitle>
      <Content>
        <RoleProvider role={data || {}}>
          {tabMaps.map(
            (t, index) =>
              t.visible && (
                <TabPanel key={t.label} index={index} value={value}>
                  {t.component}
                </TabPanel>
              ),
          )}
        </RoleProvider>
      </Content>
    </Container>
  )
}
