import { QueryClientProvider } from '@tanstack/react-query'

import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { ToastContainer } from 'react-toastify'
// import { theme } from '@data-c/ui'
import theme from 'styles/theme'
import AppRoutes from './routes'
import queryClient from './services/query-client'
import 'react-toastify/dist/ReactToastify.css'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/pt-br'
import UserProvider from 'components/Contexts/UserContext'

function App() {
  return (
    <ThemeProvider theme={{ ...theme }}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </LocalizationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  )
}

export default App
