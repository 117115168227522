import { PageTitle } from '@data-c/ui'
import { Box, Container, Stack, Typography } from '@mui/material'
import unauthorizedImage from 'assets/images/unauthorized.svg'

export default function Unauthorized() {
  return (
    <Container>
      <PageTitle title="Acesso Restrito"></PageTitle>
      <Box
        sx={{
          height: `100vh`,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={unauthorizedImage} width={500} />
        <Stack spacing={2}>
          <Typography sx={{ textAlign: 'center' }} variant="body2">
            Ops!!! Parece que você não tem permissão apara acessar esta página
          </Typography>
        </Stack>
      </Box>
    </Container>
  )
}
