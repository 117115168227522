import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useEmpresas, { EmpresasModel } from 'hooks/queries/useEmpresas'

import { formatToCPFOrCNPJ } from 'brazilian-values'
import { useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import {
  TransportableDataTableProps,
  findData,
  usePagination,
} from '@data-c/hooks'
import { useUserContext } from 'components/Contexts/UserContext'
import useNotification from 'hooks/useNotification'

export default function Table(
  props: TransportableDataTableProps<EmpresasModel>,
) {
  const { onTransport, enableTransporter, query } = props
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useEmpresas()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: {
      plainQuery: query,
    },
    pagination,
  })
  const { openForm, openConfirm } = useFormApi()
  const { temPermissao } = useUserContext()
  const { extractAxiosError } = useNotification()
  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: EmpresasModel) => {
      switch (event) {
        case 'edit':
          openForm(data)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
      }
    },
    [openForm, openConfirm],
  )

  const empresas = data?.data.map((empresa) => ({
    ...empresa,
    documento: formatToCPFOrCNPJ(empresa.documento),
  }))

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'documento',
        label: 'Documento',
        options: {
          setCellProps: () => ({
            style: {
              width: '125px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'razaoSocial',
        label: 'Razão Social',
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'protegida',
        label: 'Protegida',
        options: {
          display: Boolean(temPermissao('empresas.protegidas')),
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<EmpresasModel>(data?.data || [], value)
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      surface
      error={extractAxiosError(error)}
      columns={columns}
      data={empresas || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
