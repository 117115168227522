import { Stack } from '@mui/material'
import Editor from '@monaco-editor/react'
import * as monaco from 'monaco-editor'
import { useEffect, useRef, useState } from 'react'
import { Button, ButtonContainer } from '@data-c/ui'
import useAplicativos, { AplicativosModel } from 'hooks/queries/useAplicativos'
import useNotification from 'hooks/useNotification'

export interface TabCadstroProps {
  data: AplicativosModel
}

export default function TabConfigs(props: TabCadstroProps) {
  const { data } = props
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const notification = useNotification()
  const { useSubmit } = useAplicativos() // Use o hook de CRUD
  const { mutateAsync: salvar, isLoading } = useSubmit()

  const [json, setJson] = useState('')

  const handleEditorChange = (value: any) => {
    setJson(value)
  }

  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
  ) => {
    editorRef.current = editor
    editor.getAction('editor.action.formatDocument')?.run()
    editor.setValue(JSON.stringify(data?.configs || {}))
  }

  const formatDocument = () => {
    if (editorRef.current) {
      editorRef.current.getAction('editor.action.formatDocument')?.run()
    }
  }

  useEffect(() => {
    setJson(JSON.stringify(data?.configs || {}))
    formatDocument()
  }, [data])

  async function handleSalvar() {
    try {
      const jsonData = JSON.parse(json) // Valida o JSON
      await salvar({ ...data, configs: jsonData })
    } catch (error: any) {
      notification.notifyError('Erro ' + error.message)
    }
  }

  return (
    <Stack spacing={2}>
      <ButtonContainer>
        <Button onClick={formatDocument}>Formatar</Button>
        <Button
          isLoading={isLoading}
          variant="contained"
          onClick={handleSalvar}
        >
          Salvar
        </Button>
      </ButtonContainer>
      <Editor
        height="82vh"
        defaultLanguage="json"
        defaultValue={json}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          fontSize: 14,
          automaticLayout: true,
          formatOnType: true, // Formata automaticamente enquanto você digita
          formatOnPaste: true, // Formata automaticamente quando você cola código
          tabSize: 2, // Define o tamanho da tabulação
          insertSpaces: true, // Insere espaços em vez de tabs
        }}
      />
    </Stack>
  )
}
