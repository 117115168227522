import { ButtonContainer, Dialog, Button } from '@data-c/ui'
import {
  EmpresaConfigsFormDataProps,
  useUpdateEmpresaConfigs,
} from 'hooks/queries/useUsuarios'
import { useEffect, useRef, useState } from 'react'
import Editor from '@monaco-editor/react'
import * as monaco from 'monaco-editor'
import useNotification from 'hooks/useNotification'

export interface EmpresaConfigsFormProps {
  isOpen: boolean
  onClose: () => void
  data: EmpresaConfigsFormDataProps
}

export default function EmpresaConfigsForm(props: EmpresaConfigsFormProps) {
  const { isOpen, onClose, data } = props
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const configs = data?.empresa?.configs
  const [config, setConfig] = useState<string>()
  const { mutateAsync, isLoading } = useUpdateEmpresaConfigs()
  const notification = useNotification()

  const handleEditorChange = (value: any) => {
    setConfig(value)
  }

  const handleEditorDidMount = (
    editor: monaco.editor.IStandaloneCodeEditor,
  ) => {
    editorRef.current = editor
    editor.getAction('editor.action.formatDocument')?.run()
    editor.setValue(JSON.stringify(configs || {}))
  }

  const formatDocument = () => {
    if (editorRef.current) {
      editorRef.current.getAction('editor.action.formatDocument')?.run()
    }
  }

  async function handleSave() {
    try {
      const jsonData = JSON.parse(config || '{}') // Valida o JSON
      await mutateAsync({
        ...data,
        configs: jsonData,
      })
      onClose()
    } catch (e) {
      notification.notifyException(e)
    }
  }

  useEffect(() => {
    setConfig(JSON.stringify(configs))
    formatDocument()
  }, [configs])

  return (
    <Dialog
      title="Configurações"
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      actions={
        <ButtonContainer>
          <Button
            color="error"
            title="Cancelar"
            onClick={() => {
              onClose()
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSave()
            }}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </ButtonContainer>
      }
    >
      <Editor
        height="60vh"
        defaultLanguage="json"
        defaultValue={config}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          fontSize: 14,
          automaticLayout: true,
          formatOnType: true, // Formata automaticamente enquanto você digita
          formatOnPaste: true, // Formata automaticamente quando você cola código
          tabSize: 2, // Define o tamanho da tabulação
          insertSpaces: true, // Insere espaços em vez de tabs
        }}
      />
    </Dialog>
  )
}
