import { IconButton, IconProps, Stack } from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'
import { KeyboardArrowRight as MuiToogleMenuIcon } from '@mui/icons-material'

interface ToogleMenuIconProps extends IconProps {
  open?: boolean
}

const transformToogleIcon = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['transform'], {
    duration: 500,
  }),
})

const ToogleMenuIcon = styled(MuiToogleMenuIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ToogleMenuIconProps>(({ theme, open }) => ({
  color: theme.palette.primary.dark,
  ...transformToogleIcon(theme),
  ...(open && {
    transform: 'rotate(-180deg)',
  }),
  ...(!open && {
    transform: 'rotate(0)',
  }),
}))

interface ToogleMenuButtonProps {
  open: boolean
  onToogleDrawerMenu: () => void
}

export default function ToogleMenuButton(props: ToogleMenuButtonProps) {
  const { open, onToogleDrawerMenu } = props

  return (
    <Stack
      width="90%"
      direction="row"
      justifyContent={open ? 'flex-end' : 'center'}
      margin="16px auto"
    >
      <IconButton
        size="small"
        aria-label="open drawer"
        onClick={onToogleDrawerMenu}
      >
        <ToogleMenuIcon fontSize="small" open={open} />
      </IconButton>
    </Stack>
  )
}
