import { useCredentials } from '@data-c/hooks'
import { Box, useTheme } from '@mui/material'
import Header from 'components/App/Header'
import Menu from 'components/App/Menu'
import { useUserContext } from 'components/Contexts/UserContext'
import Unauthorized from 'components/Displays/Unauthorized'
import credentials from 'configs/credentials'
import { ReactNode, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

export const drawerWidth = 256

interface LayoutProps {
  children: ReactNode
  role?: string
}

export default function Layout(props: LayoutProps) {
  const { children, role } = props
  const { isAuthenticated } = useCredentials(credentials)
  const { temPermissao, loadUser, isLoading } = useUserContext()
  const theme = useTheme()
  useEffect(() => {
    loadUser()
  }, [])

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  let podeAcessar = true

  if (role && !temPermissao(role)) {
    podeAcessar = false
  }

  const menuItens = [
    {
      id: 'menu_aplicativos',
      label: 'Aplicativos',
      icon: 'devices',
      link: '/aplicativos',
      visible: temPermissao('aplicativos.visibilidade'),
    },
    {
      id: 'menu_empresas',
      label: 'Empresas',
      icon: 'store',
      link: '/empresas',
      visible: temPermissao('empresas.visibilidade'),
    },
    {
      id: 'menu_usuarios',
      label: 'Usuários',
      icon: 'group',
      link: '/usuarios',
      visible: temPermissao('usuarios.visibilidade'),
    },
    {
      id: 'menu_contratos',
      label: 'Contratos',
      icon: 'handshake',
      link: '/contratos',
      visible: temPermissao('contratos.visibilidade'),
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
        marginTop: `${theme.mixins.toolbar.height}px`,
      }}
    >
      <Header />
      <Menu menus={menuItens} />
      {podeAcessar && children}
      {!podeAcessar && !isLoading && <Unauthorized />}
    </Box>
  )
}
