import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'
import useUsuarios from 'hooks/queries/useUsuarios'
import Delete from 'components/Delete'

import { useState } from 'react'
import { Content, PageTitle } from '@data-c/ui'

export default function Usuarios() {
  const { useDelete } = useUsuarios()
  const { mutateAsync } = useDelete()
  const [query, setQuery] = useState<string>()
  return (
    <Container>
      <FormProvider initialFormValues={{ isActive: true }}>
        <PageTitle
          title="Usuários"
          onSearch={(q) => {
            setQuery(q)
          }}
          storagePath="filter:usuarios"
        >
          <Form />
        </PageTitle>
        <Content>
          <Table query={query} />
        </Content>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Container>
  )
}
