import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function TabVisibilidade() {
  const { role, changeRolePermission } = useRoleContext()
  const { empresas, aplicativos, usuarios, permissoes, contratos } =
    role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Aplicativos"
          description="Define se o usuário tem permissão para acessar o menu Aplicativos"
          collapse={Boolean(aplicativos?.visibilidade)}
          component={
            <Switch
              name="aplicativos.visibilidade"
              checked={Boolean(aplicativos?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Empresas"
          description="Define se o usuário tem permissão para acessar o menu Empresas"
          component={
            <Switch
              name="empresas.visibilidade"
              checked={Boolean(empresas?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Usuários"
          description="Define se o usuário tem permissão para acessar o menu Usuários"
          collapse={Boolean(usuarios?.visibilidade)}
          component={
            <Switch
              name="usuarios.visibilidade"
              checked={Boolean(usuarios?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Contratos"
          description="Define se o usuário tem permissão para acessar os contratos"
          collapse={Boolean(contratos?.visibilidade)}
          component={
            <Switch
              name="contratos.visibilidade"
              checked={Boolean(contratos?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Permissões"
          description="Define se o usuário tem permissão para acessar o gerenciador de permissões"
          collapse={Boolean(permissoes?.visibilidade)}
          component={
            <Switch
              name="permissoes.visibilidade"
              checked={Boolean(permissoes?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        {/* <CardConfig
          title="Configurações"
          description="Define se o usuário tem permissão para acessar o menu Configurações"
          component={
            <Switch
              name="configuracoes.visibilidade"
              checked={Boolean(configuracoes?.visibilidade)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        /> */}
      </Stack>
    </Box>
  )
}
