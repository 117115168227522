import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'
import UsuarioTable from 'pages/Usuarios/components/Table'
import { formatToCPFOrCNPJ } from 'brazilian-values'
type UsuarioTransporterProps = {} & Omit<
  TransporterProps<UsuariosModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function UsuarioTransporter(props: UsuarioTransporterProps) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useUsuarios()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={UsuarioTable}
      label="Usuario"
      renderValue={(value) => {
        return `${value.name} (${formatToCPFOrCNPJ(value?.documento || '')} )`
      }}
      value={value}
      {...rest}
    />
  )
}
