import { useMemo, useCallback } from 'react'
import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useRole, { RoleModel } from 'hooks/queries/useRole'
import {
  PlainQueryProps,
  TransportableDataTableProps,
  findData,
} from '@data-c/hooks'
import { useNavigate } from 'react-router-dom'
import { useFormApi } from '@data-c/providers'
import useNotification from 'hooks/useNotification'
// import { useFormApi } from '@data-c/providers'

export default function Table(props: TransportableDataTableProps<RoleModel>) {
  const { onTransport, enableTransporter } = props
  const { openConfirm } = useFormApi<RoleModel>()
  const { extractAxiosError } = useNotification()
  const navigate = useNavigate()
  const { useQuery } = useRole()
  const { data, error, isFetching, isLoading } = useQuery<PlainQueryProps>({
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  const roles = useMemo(
    () =>
      data?.data?.map((d) => {
        return {
          ...d,
        }
      }),
    [data],
  )

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', value: RoleModel) => {
      switch (event) {
        case 'edit':
          navigate(`/permissao/${value.uuid}`)
          // openForm(value)
          break
        case 'delete':
          openConfirm(value)
          break
        case 'transport':
          onTransport && onTransport(value)
      }
    },
    [],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<RoleModel>(roles || [], value, 'uuid')
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [roles, handleClickItem],
  )

  return (
    <DataTable
      surface
      error={extractAxiosError(error)}
      columns={columns}
      data={roles || []}
      isLoading={isLoading}
      isFetching={isFetching}
    />
  )
}
