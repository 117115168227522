import { Paper } from '@mui/material'
import { AplicativosModel } from 'hooks/queries/useAplicativos'
import Form from 'pages/Aplicativos/components/Form'

export interface TabCadstroProps {
  data: AplicativosModel
}
export default function TabCadstro({ data }: TabCadstroProps) {
  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Form data={data} view="plain" />
    </Paper>
  )
}
