import { Box, Paper } from '@mui/material'
import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'
import Table from './components/Table'
import { ButtonContainer } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'
import Form from './components/Form'
import Delete from 'components/Delete'

interface TabAplicativosProps {
  usuario?: UsuariosModel
}

export default function TabAplicativos(props: TabAplicativosProps) {
  const { usuario } = props
  const { useRemoveAllPermissions } = useUsuarios()
  const { mutateAsync } = useRemoveAllPermissions()
  return (
    <Box>
      <FormProvider initialFormValues={{ usuarioUuid: usuario?.uuid }}>
        <ButtonContainer>
          <Form usuarioUuid={usuario?.uuid} />
        </ButtonContainer>
        <Paper sx={{ p: 2, mt: 2 }}>
          <Table
            usuarioUuid={usuario?.uuid}
            permissoes={usuario?.permissoesAsArray}
          />
        </Paper>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Box>
  )
}
