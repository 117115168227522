import { findData, useDialog } from '@data-c/hooks'
import { useFormApi } from '@data-c/providers'
import {
  DataTable,
  Dialog,
  OptionStyles,
  DataTableOptions as Options,
  ButtonContainer,
  Button,
} from '@data-c/ui'
import { Delete } from '@mui/icons-material'
import { Chip, Stack } from '@mui/material'
import { formatToCPFOrCNPJ } from 'brazilian-values'
import { EmpresasModel } from 'hooks/queries/useEmpresas'
import useUsuarios, {
  EmpresaConfigsFormDataProps,
  Permissao,
  RemoveAllPermissionsFormProps,
  RemoveOnePermissionFormProps,
} from 'hooks/queries/useUsuarios'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { useCallback, useMemo } from 'react'
import EmpresaConfigsForm from '../EmpresaConfigsForm'

export interface TableProps {
  usuarioUuid?: string
  permissoes?: Array<Permissao>
}

export default function Table(props: TableProps) {
  const { permissoes, usuarioUuid } = props
  const {
    isOpen,
    openDialog,
    closeDialog,
    data: permissaoParaExcluir,
  } = useDialog<RemoveOnePermissionFormProps>()
  const {
    isOpen: isOpenEmpresaConfigsForm,
    openDialog: openDialogEmpresaConfigsForm,
    closeDialog: closeDialogEmpresaConfigsForm,
    data: empresaConfigsData,
  } = useDialog<EmpresaConfigsFormDataProps>()
  const { useRemoveOnePermission } = useUsuarios()
  const { mutateAsync, isLoading } = useRemoveOnePermission()
  const { openConfirm } = useFormApi<RemoveAllPermissionsFormProps>()
  const handleClickItem = useCallback(
    (event: 'edit' | 'delete', data: Permissao) => {
      switch (event) {
        case 'delete':
          openConfirm({
            usuarioUuid: usuarioUuid || '',
            permissao: data,
          })
          break
      }
    },
    [usuarioUuid],
  )

  function handleClickRevokeOne(permissao: Permissao, empresa: EmpresasModel) {
    if (usuarioUuid && empresa?.uuid) {
      openDialog({ usuarioUuid, empresaUuid: empresa.uuid, permissao })
    }
  }

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'key',
        label: 'Identificador',
        options: {
          setCellProps: () => ({
            style: {
              width: '96px',
            },
          }),
        },
      },
      {
        name: 'nome',
        label: 'Nome',
      },
      {
        name: 'key',
        label: 'Empresas',
        options: {
          setCellProps: () => ({
            style: {
              // width: '100px',
              // textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            const _data = findData<Permissao>(permissoes || [], value, 'key')
            if (!_data) {
              return ''
            }
            const empresas = _data.empresas as Array<EmpresasModel>
            if (!empresas) {
              return ''
            }
            return (
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                flexWrap="wrap"
                // sx={{ backgroundColor: '#f4f', width: '80%' }}
              >
                {empresas.map((empresa) => (
                  <Chip
                    key={empresa.uuid}
                    deleteIcon={<Delete />}
                    onClick={() => {
                      openDialogEmpresaConfigsForm({
                        empresa: empresa,
                        permissao: _data,
                        usuarioUuid: usuarioUuid || '',
                      })
                    }}
                    onDelete={
                      empresas.length > 1
                        ? () => {
                            if (_data) {
                              handleClickRevokeOne(_data, empresa)
                            }
                          }
                        : undefined
                    }
                    size="small"
                    label={`${empresa?.nome} (${formatToCPFOrCNPJ(
                      empresa?.documento,
                    )})`}
                  />
                ))}
              </Stack>
            )
          },
        },
      },
      {
        name: 'key',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<Permissao>(permissoes || [], value, 'key')
            return (
              <Options
                displayUpdateButton={false}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [permissoes, usuarioUuid],
  )

  return (
    <>
      <DataTable columns={columns} data={permissoes || []} />
      <Dialog
        title="Excluir Registro Permanentemente?"
        type="error"
        open={isOpen}
        maxWidth="xs"
        onClose={closeDialog}
        actions={
          <ButtonContainer>
            <Button
              title="Cancelar alteração de senha"
              onClick={() => closeDialog()}
            >
              Cancelar
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                if (permissaoParaExcluir) {
                  mutateAsync(permissaoParaExcluir)
                  closeDialog()
                }
              }}
              isLoading={isLoading}
            >
              Excluir
            </Button>
          </ButtonContainer>
        }
      >
        Você não poderá recuperar este registro após excluí-lo!
      </Dialog>
      <EmpresaConfigsForm
        isOpen={isOpenEmpresaConfigsForm}
        onClose={closeDialogEmpresaConfigsForm}
        data={empresaConfigsData || ({} as EmpresaConfigsFormDataProps)}
      />
    </>
  )
}
