import { Alert, Grid, MenuItem, TextField } from '@mui/material'
import { useFormApi, useFormData } from '@data-c/providers'
import { Checkbox, FormContainer } from '@data-c/ui'
import useValidations from 'hooks/useValidations'
import useContratos, { ContratoModel } from 'hooks/queries/useContratos'
import * as yup from 'yup'
import UsuarioTransporter from 'components/Transporters/UsuariosTransporter'
import { UsuariosModel } from 'hooks/queries/useUsuarios'
import AplicativoTransporter from 'components/Transporters/AplicativoTransporter'
import { AplicativosModel } from 'hooks/queries/useAplicativos'
import EmpresaTransporter from 'components/Transporters/EmpresasTransporter'
import { EmpresasModel } from 'hooks/queries/useEmpresas'

const schema = yup.object().shape({
  usuarioUuid: yup.string().required('Informe o UUID do Usuário'),
  aplicativoUuid: yup.string().required('Informe o UUID do Aplicativo'),
  planoContratado: yup.object().required('Informe o Plano Contratado'),
})

export default function Form() {
  const { formValues: data } = useFormData<ContratoModel>()
  const { onChangeFormValue, toggleSubmit, closeForm, changeValues } =
    useFormApi<ContratoModel>()
  const { useSubmit } = useContratos()
  const { mutateAsync } = useSubmit()
  const { setValidationErrors, validationProps } = useValidations()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync({
          usuarioUuid: data.usuarioUuid,
          empresaUuid: data.empresaUuid,
          aplicativoUuid: data.aplicativoUuid,
          planoContratado: data.planoContratado,
          ativo: true,
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }
  console.log(data)
  const planos = data?.aplicativo?.configs?.planos || []
  return (
    <FormContainer
      onSubmitForm={handleSubmitForm}
      dialogProps={{
        maxWidth: 'sm',
        title: data?.uuid ? 'Editar Contrato' : 'Novo Contrato',
        disableRestoreFocus: true,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UsuarioTransporter
            value={data?.usuario || null}
            onChange={(value: UsuariosModel | null) => {
              changeValues({
                ...data,
                usuarioUuid: value?.uuid || null,
                usuario: value || null,
              })
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AplicativoTransporter
            name="aplicativoUuid"
            value={data?.aplicativo || null}
            onChange={(value: AplicativosModel | null) => {
              changeValues({
                ...data,
                aplicativoUuid: value?.uuid || null,
                aplicativo: value || null,
              })
            }}
            {...validationProps('aplicativoUuid')}
          />
        </Grid>

        {planos.length > 0 && (
          <Grid item xs={12}>
            <TextField
              select
              name="planoContratado"
              label="Plano"
              value={data?.planoContratado || ''}
              onChange={onChangeFormValue}
              {...validationProps('planoContratado')}
              required
            >
              {planos.map((p: any) => (
                <MenuItem value={p}>{p.nome}</MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        {data?.aplicativo && planos.length == 0 && (
          <Grid item xs={12}>
            <Alert severity="error">
              O aplicativo ainda não possui nenhum plano configurado
            </Alert>
          </Grid>
        )}

        {Boolean(data?.aplicativo?.habilitaEmpresa) && (
          <Grid item xs={12}>
            <EmpresaTransporter
              value={data?.empresa || null}
              onChange={(value: EmpresasModel | null) => {
                changeValues({
                  ...data,
                  empresaUuid: value?.uuid || null,
                  empresa: value || null,
                })
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Checkbox
            label="Ativo"
            name="ativo"
            onChange={onChangeFormValue}
            checked={Boolean(data?.ativo)}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
