import api from 'services/api'
import { RoleModel } from './useRole'

export interface UsuarioModel {
  uuid: string
  email: string
  role: RoleModel
  roleUuid?: string
}

export async function obterUsuarioLogado(): Promise<UsuarioModel> {
  const response = await api.get<UsuarioModel>(`/usuario-logado`)
  return response.data
}
