import credentialsConfigs from 'configs/credentials'

export function getToken() {
  return localStorage.getItem(credentialsConfigs.tokenKey)
}

export function getRefreshToken() {
  return localStorage.getItem(credentialsConfigs.tokenKey)
}

export function setToken(token: string) {
  return localStorage.setItem(credentialsConfigs.tokenKey, token)
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(credentialsConfigs.refreshTokenKey, refreshToken)
}
