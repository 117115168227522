import useCrud, { EntityInterface, generateEntityKey } from 'hooks/useCrud'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotification'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AplicativosModel } from './useAplicativos'
import { EmpresasModel } from './useEmpresas'
import api from 'services/api'
import { RoleModel } from './useRole'

export interface AddPermissionFormProps {
  usuarioUuid: string
  aplicativoUuid?: string | null
  aplicativo?: AplicativosModel | null
  empresaUuid?: string | null
  empresa?: EmpresasModel | null
}

export interface EmpresaConfigsFormDataProps {
  usuarioUuid: string
  permissao: Permissao
  empresa: EmpresasModel
  configs?: object
}

export interface RemoveAllPermissionsFormProps {
  usuarioUuid: string
  permissao: Permissao
}

export interface RemoveOnePermissionFormProps {
  usuarioUuid: string
  empresaUuid: string
  permissao: Permissao
}

export interface Permissao {
  uuid: string
  key: string
  nome: string
  ativo: boolean
  empresas?: Array<EmpresasModel>
}

export interface UsuariosModel extends EntityInterface {
  uuid?: string
  documento: string
  email: string
  name: string
  roleUuid?: string | null
  role?: RoleModel | null
  isActive: boolean
  permissoes: Record<string, Permissao>
  permissoesAsArray: Array<Permissao>
}

export function useAddPermission() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function addPermission(data: AddPermissionFormProps): Promise<void> {
    const { usuarioUuid, aplicativoUuid, empresaUuid } = data
    await api.post<AddPermissionFormProps>(
      `v2/usuarios/${usuarioUuid}/add-permission`,
      { aplicativoUuid, empresaUuid },
    )
  }

  return useMutation<void, AxiosError, AddPermissionFormProps>(
    (data) => addPermission(data),
    {
      onSuccess() {
        const entityKey = generateEntityKey('Usuário')
        notifications.notifySuccess('Permissão adicionada com sucesso')
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useRemoveAllPermissions() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function removeAllPermissions(
    data: RemoveAllPermissionsFormProps,
  ): Promise<void> {
    const { usuarioUuid, permissao } = data
    await api.delete<AddPermissionFormProps>(
      `v2/usuarios/${usuarioUuid}/${permissao.key}/revoke`,
    )
  }

  return useMutation<void, AxiosError, RemoveAllPermissionsFormProps>(
    (params) => removeAllPermissions(params),
    {
      onSuccess() {
        const entityKey = generateEntityKey('Usuário')
        notifications.notifySuccess('Permissão removida com sucesso')
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useRemoveOnePermission() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function removeOnePermission(
    data: RemoveOnePermissionFormProps,
  ): Promise<void> {
    const { usuarioUuid, empresaUuid, permissao } = data
    await api.delete<AddPermissionFormProps>(
      `v2/usuarios/${usuarioUuid}/${permissao.key}/${empresaUuid}/revoke-one`,
    )
  }

  return useMutation<void, AxiosError, RemoveOnePermissionFormProps>(
    (params) => removeOnePermission(params),
    {
      onSuccess() {
        const entityKey = generateEntityKey('Usuário')
        notifications.notifySuccess('Permissão removida com sucesso')
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export function useUpdateEmpresaConfigs() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  async function updateEmpresaConfigs(
    data: EmpresaConfigsFormDataProps,
  ): Promise<void> {
    const { usuarioUuid, permissao, empresa, configs } = data
    await api.put<AddPermissionFormProps>(
      `v2/usuarios/${usuarioUuid}/${permissao.key}/${empresa.uuid}/update-empresa-configs`,
      configs,
    )
  }

  return useMutation<void, AxiosError, EmpresaConfigsFormDataProps>(
    (data) => updateEmpresaConfigs(data),
    {
      onSuccess() {
        const entityKey = generateEntityKey('Usuário')
        notifications.notifySuccess('Configuração atualizada com sucesso')
        queryClient.invalidateQueries([entityKey])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useUsuarios() {
  return {
    ...useCrud<UsuariosModel, AxiosError>('/v2/usuarios', 'Usuário', 'male'),
    useAddPermission,
    useRemoveAllPermissions,
    useRemoveOnePermission,
    useUpdateEmpresaConfigs,
  }
}
