import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useRole, { RoleModel } from 'hooks/queries/useRole'
import RoleTable from 'pages/Roles/components/Table'

type RolesTransporterProps = {} & Omit<
  TransporterProps<RoleModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function RolesTransporter(props: RolesTransporterProps) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useRole()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={RoleTable}
      label="Permissões"
      renderValue={(value) => {
        return `${value.nome}`
      }}
      value={value}
      {...rest}
    />
  )
}
