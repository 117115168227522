import { Alert, Container, Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import useAplicativos, { AplicativosModel } from 'hooks/queries/useAplicativos'
import useTabs from 'hooks/useTabs'
import { useNavigate, useParams } from 'react-router-dom'
import TabCadstro from './components/TabCadastro'
import Content from 'components/App/Content'
import { PageTitle } from '@data-c/ui'
import { FormProvider } from '@data-c/providers'
import Loading from 'components/Displays/Loading'
import TabConfigs from './components/TabConfigs'

export default function Aplicativo() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { handleChange, value } = useTabs()
  const { useQueryByUuid } = useAplicativos()
  const { data, isLoading, error } = useQueryByUuid<AplicativosModel>(
    id as string,
  )

  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <PageTitle title={data?.nome || ''} onBack={() => navigate(-1)}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs mix de produtos"
        >
          <Tab label="Cadastro" value={0} />
          <Tab label="Configurações" value={1} />
        </Tabs>
      </PageTitle>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Content>
        <TabPanel value={value} index={0}>
          <FormProvider>
            <TabCadstro data={data || ({} as AplicativosModel)} />
          </FormProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabConfigs data={data || ({} as AplicativosModel)} />
        </TabPanel>
      </Content>
    </Container>
  )
}
