import { InputProps, TextField, TextFieldProps } from '@mui/material'
import { forwardRef } from 'react'
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask'

interface MaskedTextFieldProps extends ReactInputMaskProps {
  inputProps?: TextFieldProps
  InputProps?: InputProps
}

const MaskedTextField = forwardRef<
  React.InputHTMLAttributes<HTMLInputElement>,
  MaskedTextFieldProps
>((props, ref?) => {
  return (
    <ReactInputMask {...props}>
      {/* @ts-ignore */}
      {(inputProps): Element => (
        <TextField inputRef={ref} {...props.inputProps} {...inputProps} />
      )}
    </ReactInputMask>
  )
})

export default MaskedTextField
