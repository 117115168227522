import { MenuItem as MuiMenuItem, MenuItemProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledMuiMenuItem = styled(
  MuiMenuItem,
  {},
)(({ theme }) => ({
  borderRadius: '6px',
  width: '90%',
  gap: '16px',
  margin: '0 auto',
  padding: '8px 16px',
  transition: '0.1s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(89, 195, 224, 15%);',
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiIcon-root': {
    color: theme.palette.grey[500],
    fontSize: '20px',
  },
  '& .MuiTypography-root': {
    letterSpacing: '0.5px',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
  },
}))

const MenuItem = (props: MenuItemProps) => <StyledMuiMenuItem {...props} />

export default MenuItem
