import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function TabEmpresas() {
  const { role, changeRolePermission } = useRoleContext()
  const { empresas, permissoes } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Ver"
          description={
            <div>Define se o usuário possui permissão para ver as empresas</div>
          }
          component={
            <Switch
              name="empresas.read"
              checked={empresas?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Criar"
          description={
            <div>Define se o usuário possui permissão para criar empresas</div>
          }
          component={
            <Switch
              name="empresas.create"
              checked={empresas?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Alterar"
          description={
            <div>
              Define se o usuário possui permissão para alterar empresas
            </div>
          }
          component={
            <Switch
              name="empresas.update"
              checked={empresas?.update || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Deletar"
          description={
            <div>
              Define se o usuário possui permissão para deletar empresas
            </div>
          }
          component={
            <Switch
              name="empresas.delete"
              checked={empresas?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acessar empresas protegidas"
          description="Define se o usuário tem permissão para acessar as empresas protegidas"
          component={
            <Switch
              name="empresas.protegidas"
              checked={Boolean(empresas?.protegidas)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa) || !Boolean(permissoes?.protegidas)}
            />
          }
        />
      </Stack>
    </Box>
  )
}
