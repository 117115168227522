import { AxiosError } from 'axios'
import useCrud, { EntityInterface } from 'hooks/useCrud'

export interface EmpresasModel extends EntityInterface {
  tipo: 'PESSOA_FISICA' | 'PESSOA_JURIDICA'
  documento: string
  razaoSocial?: string
  nome?: string
  ativo?: boolean
  protegida?: boolean
  configs?: object
}

export default function useEmpresas() {
  return useCrud<EmpresasModel, AxiosError>(
    '/v2/empresas',
    'Empresas',
    'female',
  )
}
