import { useMemo, useCallback } from 'react'
import {
  DataTable,
  DataTableOptions as Options,
  Flag,
  OptionStyles,
  CurrencyCellStyle,
} from '@data-c/ui'
import { useFormApi } from '@data-c/providers'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useContratos, { ContratoModel } from 'hooks/queries/useContratos'
import {
  formatCurrency,
  TransportableDataTableProps,
  usePagination,
} from '@data-c/hooks'
import { useNavigate } from 'react-router-dom'

export default function Table(_: TransportableDataTableProps<ContratoModel>) {
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useContratos()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: {}, // Pode adicionar filtros aqui
    pagination,
  })
  const { openForm, openConfirm } = useFormApi()
  const navigate = useNavigate()
  const findData = useCallback(
    (value: string) => {
      return data?.data.find((d: ContratoModel) => d.uuid === value)
    },
    [data],
  )

  const formatted = data?.data.map((d) => ({
    ...d,
    nomeUsuario: d.usuario?.name || '',
    nomePlanoContratado: d.planoContratado.nome,
    nomeEmpresa: d.empresa?.razaoSocial || '---',
    valorAsCurrency: formatCurrency(d?.valorContrato || '0'),
  }))

  const handleClickItem = useCallback(
    (event: 'detalhes', data: ContratoModel) => {
      switch (event) {
        case 'detalhes':
          navigate(`/contratos/${data.uuid}`)
          break
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'nomePlanoContratado',
        label: 'Plano Contratado',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
            },
          }),
        },
      },
      {
        name: 'nomeUsuario',
        label: 'Contratante',
      },
      {
        name: 'nomeEmpresa',
        label: 'Empresa',
      },
      {
        name: 'valorAsCurrency',
        label: 'Valor',
        options: {
          setCellProps: CurrencyCellStyle,
        },
      },
      {
        name: 'recorrencia',
        label: 'Recorrência',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
            },
          }),
        },
      },
      {
        name: 'inicioContratAsBrazilianDate',
        label: 'Início',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
            },
          }),
        },
      },
      {
        name: 'fimContratoAsRelativeDate',
        label: 'Fim/Renovação',
        options: {
          setCellProps: () => ({
            style: {
              width: '120px',
            },
          }),
        },
      },
      {
        name: 'renovacaoAutomatica',
        label: 'Ren. Aut.',
        options: {
          setCellProps: () => ({
            style: {
              width: '64px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => (
            <Flag isFlagged={value} type="success" />
          ),
        },
      },
      {
        name: 'ativo',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '48px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => (
            <Flag isFlagged={value} type="success" />
          ),
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData(value)
            return (
              <Options
                displayDeleteButton={false}
                displayUpdateButton={false}
                extraOptions={[
                  {
                    id: 'detalhes',
                    icon: 'settings',
                    iconButtonProps: {
                      color: 'primary',
                    },
                    visible: true,
                  },
                ]}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [findData, handleClickItem],
  )

  return (
    <DataTable
      surface
      error={error?.message}
      columns={columns}
      data={formatted || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
    />
  )
}
