import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
} from '@mui/material'
import { FormContainer } from '@data-c/ui'
import { useFormApi, useFormData } from '@data-c/providers'

import useEmpresas, { EmpresasModel } from 'hooks/queries/useEmpresas'

import * as yup from 'yup'
import { useValidations } from '@data-c/hooks'
import DocumentoTextField, {
  TIPO_DOCUMENTO,
} from 'components/Inputs/DocumentoTextField'
import { useUserContext } from 'components/Contexts/UserContext'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
  documento: yup.string().required('Informe o documento'),
})

export default function Form() {
  const { onChangeFormValue, toggleSubmit, closeForm } =
    useFormApi<EmpresasModel>()
  const { temPermissao } = useUserContext()
  const { formValues: data } = useFormData<EmpresasModel>()
  const { setValidationErrors, validationProps } = useValidations()
  const { useSubmit } = useEmpresas()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Cadastro de Empresa' }}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <RadioGroup
            row
            value={data?.tipo}
            onChange={onChangeFormValue}
            name="tipo"
          >
            <FormControlLabel
              value="PESSOA_FISICA"
              control={<Radio />}
              label="Pessoa Física"
            />
            <FormControlLabel
              value="PESSOA_JURIDICA"
              control={<Radio />}
              label="Pessoa Jurídica"
            />
          </RadioGroup>
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <DocumentoTextField
            name="documento"
            inputProps={{
              label: 'Documento',
              name: 'documento',
              ...validationProps('documento'),
            }}
            value={data?.documento || ''}
            onChange={onChangeFormValue}
            tipoDocumento={
              data?.tipo == 'PESSOA_FISICA'
                ? TIPO_DOCUMENTO.CPF
                : TIPO_DOCUMENTO.CNPJ
            }
          />
        </Grid>
        {data?.tipo === 'PESSOA_JURIDICA' && (
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <TextField
              label="Razão Social"
              name="razaoSocial"
              value={data?.razaoSocial || ''}
              onChange={onChangeFormValue}
            />
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <TextField
            label={data?.tipo === 'PESSOA_FISICA' ? 'Nome' : 'Nome Fantasia'}
            name="nome"
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
          <FormControlLabel
            label="Ativo"
            labelPlacement="end"
            control={
              <Checkbox
                name="ativo"
                checked={Boolean(data?.ativo)}
                onChange={onChangeFormValue}
              />
            }
          />
        </Grid>

        {Boolean(temPermissao('empresas.protegidas')) && (
          <Grid item xl={3} lg={3} md={3} xs={12} sm={12}>
            <FormControlLabel
              label="Proteger"
              labelPlacement="end"
              control={
                <Checkbox
                  name="protegida"
                  checked={Boolean(data?.protegida)}
                  onChange={onChangeFormValue}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </FormContainer>
  )
}
