import { FormProvider } from '@data-c/providers'
import { Container, Tabs, Tab, Paper } from '@mui/material'
import TabPanel from 'components/TabPanel'
import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'
import useTabs from 'hooks/useTabs'
import Form from 'pages/Usuarios/components/Form'
import { useNavigate, useParams } from 'react-router-dom'
import TabAplicativos from './components/TabAplicativos'
import { Content, PageTitle } from '@data-c/ui'

export default function Usuario() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { useQueryByUuid } = useUsuarios()
  const { data } = useQueryByUuid<UsuariosModel>(id || '')
  const { handleChange, value } = useTabs()
  return (
    <Container>
      <PageTitle
        onBack={() => {
          navigate(-1)
        }}
        title={`${data?.name || ''}`}
      >
        <Tabs value={value} onChange={handleChange} aria-label="tabs produtos">
          <Tab label="Cadastro" value={0} />
          <Tab label="Aplicativos" value={1} />
        </Tabs>
      </PageTitle>

      <Content>
        <FormProvider>
          <TabPanel value={value} index={0}>
            <Paper sx={{ p: 2 }}>
              <Form data={data} view="plain" />
            </Paper>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TabAplicativos usuario={data} />
          </TabPanel>
        </FormProvider>
      </Content>
    </Container>
  )
}
