import {
  DataTable,
  OptionStyles,
  DataTableOptions as Options,
  Flag,
} from '@data-c/ui'
import { MUIDataTableColumnDef } from 'mui-datatables'
import useUsuarios, { UsuariosModel } from 'hooks/queries/useUsuarios'
import { useNavigate } from 'react-router-dom'

import { useFormApi } from '@data-c/providers'
import { useMemo, useCallback } from 'react'
import {
  TransportableDataTableProps,
  findData,
  usePagination,
} from '@data-c/hooks'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import useNotification from 'hooks/useNotification'

export default function Table(
  props: TransportableDataTableProps<UsuariosModel>,
) {
  const { onTransport, enableTransporter, query } = props
  const { pagination, changePageSize, changePage } = usePagination()
  const { useQuery } = useUsuarios()
  const navigate = useNavigate()
  const { data, isLoading, isFetching, error } = useQuery({
    queryParams: {
      plainQuery: query,
    },
    pagination,
  })
  const { openForm, openConfirm } = useFormApi()
  const { extractAxiosError } = useNotification()
  const usuarios = data?.data.map((usuario) => ({
    ...usuario,
    documento: formatToCPFOrCNPJ(usuario?.documento || ''),
  }))

  const handleClickItem = useCallback(
    (event: 'edit' | 'delete' | 'transport', data: UsuariosModel) => {
      switch (event) {
        case 'edit':
          navigate(`/usuario/${data.uuid}`)
          break
        case 'delete':
          openConfirm(data)
          break
        case 'transport':
          onTransport && onTransport(data)
      }
    },
    [openForm, openConfirm],
  )

  const columns = useMemo(
    (): MUIDataTableColumnDef[] => [
      {
        name: 'documento',
        label: 'Documento',
        options: {
          setCellProps: () => ({
            style: {
              width: '125px',
            },
          }),
        },
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'name',
        label: 'Nome',
      },
      {
        name: 'ultimoLoginAsRelative',
        label: 'Último Login',
        options: {
          setCellProps: () => ({
            style: {
              width: '125px',
            },
          }),
        },
      },
      {
        name: 'isActive',
        label: 'Ativo',
        options: {
          setCellProps: () => ({
            style: {
              width: '60px',
              textAlign: 'center',
            },
          }),
          customBodyRender: (value) => {
            return <Flag isFlagged={value === 1} type="success" />
          },
        },
      },
      {
        name: 'uuid',
        label: 'Ações',
        options: {
          filter: true,
          sort: false,
          empty: true,
          setCellProps: OptionStyles,
          customBodyRender: (value) => {
            const _data = findData<UsuariosModel>(data?.data || [], value)
            return (
              <Options
                displayTransporterButton={enableTransporter}
                displayDeleteButton={!enableTransporter}
                displayUpdateButton={!enableTransporter}
                value={_data}
                onClick={handleClickItem}
              />
            )
          },
        },
      },
    ],
    [data?.data, handleClickItem],
  )

  return (
    <DataTable
      surface
      error={extractAxiosError(error)}
      columns={columns}
      data={usuarios || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      isFetching={isFetching}
      changePage={changePage}
      changePageSize={changePageSize}
      options={{
        rowsPerPageOptions: [15, 50, 100],
        rowsPerPage: 15,
      }}
    />
  )
}
