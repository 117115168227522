import { FormProvider } from '@data-c/providers'
import { Container } from '@mui/material'
import Form from './components/Form'
import Table from './components/Table'
import useContratos from 'hooks/queries/useContratos'
import Delete from 'components/Delete'
import { useState } from 'react'
import { Content, PageTitle } from '@data-c/ui'

export default function Contratos() {
  const { useDelete } = useContratos()
  const { mutateAsync } = useDelete()
  const [query, setQuery] = useState<string>()
  return (
    <Container>
      <FormProvider initialFormValues={{ ativo: true }}>
        <PageTitle
          title="Contratos"
          onSearch={(q) => {
            setQuery(q)
          }}
          storagePath="filter:aplicativos"
        >
          <Form />
        </PageTitle>
        <Content>
          <Table query={query} />
        </Content>
        <Delete onDelete={mutateAsync} />
      </FormProvider>
    </Container>
  )
}
