import { PlainQueryProps } from '@data-c/hooks'
import useCrud from 'hooks/useCrud'
// import HttpRequestInterface from 'interfaces/HttpRequestInterface'
// import HttpResponseInterface from 'interfaces/HttpResponseInterface'
// import api from 'services/api'
import { AxiosError } from 'axios'

interface RoleItem extends Record<string, any> {}

export interface RoleModel extends PlainQueryProps {
  id?: string
  uuid?: string
  origem?: string
  nome?: string
  descricao?: number
  permissoes?: Record<string, RoleItem>
  fixa?: string
}

export default function useRole() {
  return useCrud<RoleModel, AxiosError>(
    'administrador/role',
    'Permissão',
    'female',
  )
}

// export async function obterGrupoProdutos(
//   params: HttpRequestInterface<RoleModel>,
// ): Promise<HttpResponseInterface<RoleModel>> {
//   const { pagination: _pagination, queryParams } = params

//   const response = await api.get('administrador/grupo-produto', {
//     params: queryParams,
//     headers: {
//       'DC-Page': _pagination.page,
//       'DC-PageSize': _pagination.pageSize,
//     },
//   })

//   const { data, meta: pagination } = response.data

//   return { data, pagination }
// }
