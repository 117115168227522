import { List, ListProps } from '@mui/material'
import { MenuProps } from 'components/App/Menu'
import FullMenuItem from './components/FullMenuItem'

interface FullMenuProps extends MenuProps, ListProps {}

export default function FullMenu(props: FullMenuProps) {
  const { menus, ...rest } = props
  return (
    <List component="nav" disablePadding {...rest}>
      {menus.map((menuItem) => {
        return <FullMenuItem key={menuItem.id} menuItem={menuItem} />
      })}
    </List>
  )
}
