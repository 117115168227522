import { BrowserRouter, Route, Routes } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import Fallback from 'components/Fallback'
import Login from 'pages/Login'
import Empresas from 'pages/Empresas'
import Aplicativos from 'pages/Aplicativos'
import Usuarios from 'pages/Usuarios'
import Layout from 'pages/Layout/v2'
import Usuario from 'pages/Usuario'
import Roles from 'pages/Roles'
import Role from 'pages/Role'
import Aplicativo from 'pages/Aplicativo'
import Contratos from 'pages/Contratos'
import Contrato from 'pages/Contrato'

export default function AppRoutes() {
  return (
    <Sentry.ErrorBoundary
      fallback={(errorProps) => <Fallback {...errorProps} />}
    >
      <BrowserRouter>
        <Routes>
          <Route index path="/login" element={<Login />} />
          <Route index path="/" element={<Login />} />
          <Route
            index
            path="/contratos"
            element={
              <Layout>
                <Contratos />
              </Layout>
            }
          />
          <Route
            index
            path="/contratos/:id"
            element={
              <Layout>
                <Contrato />
              </Layout>
            }
          />
          <Route
            index
            path="/aplicativos"
            element={
              <Layout role="aplicativos.visibilidade">
                <Aplicativos />
              </Layout>
            }
          />
          <Route
            index
            path="/aplicativos/:id"
            element={
              <Layout role="aplicativos.visibilidade">
                <Aplicativo />
              </Layout>
            }
          />
          <Route
            index
            path="/empresas"
            element={
              <Layout role="empresas.visibilidade">
                <Empresas />
              </Layout>
            }
          />
          <Route
            index
            path="/usuarios"
            element={
              <Layout role="usuarios.visibilidade">
                <Usuarios />
              </Layout>
            }
          />
          <Route
            index
            path="/usuario/:id"
            element={
              <Layout role="usuarios.visibilidade">
                <Usuario />
              </Layout>
            }
          />
          <Route
            path="/permissoes"
            element={
              <Layout role="permissoes.visibilidade">
                <Roles />
              </Layout>
            }
          />
          <Route
            path="/permissao/:id"
            element={
              <Layout role="permissoes.visibilidade">
                <Role />
              </Layout>
            }
          />
          {/* <Route
            path="/home"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          /> */}
          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  )
}
