import { useState } from 'react'
import { PlainQueryProps } from '@data-c/hooks'
import { Transporter, TransporterProps } from '@data-c/ui'
import useEmpresas, { EmpresasModel } from 'hooks/queries/useEmpresas'
import EmpresaTable from 'pages/Empresas/components/Table'
import EmpresaForm from 'pages/Empresas/components/Form'

type EmpresaTransporterProps = {} & Omit<
  TransporterProps<EmpresasModel>,
  'table' | 'form' | 'onSearch' | 'renderValue' | 'options'
>

export default function EmpresaTransporter(props: EmpresaTransporterProps) {
  const { value, ...rest } = props
  const [query, setQuery] = useState<string | null>()
  const { useQuery } = useEmpresas()

  const { data, isLoading } = useQuery<PlainQueryProps>({
    queryParams: {
      plainQuery: query,
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  })

  function handleSearch(q: string | null) {
    setQuery(q)
  }

  return (
    <Transporter
      onSearch={handleSearch}
      options={data?.data || []}
      isLoading={isLoading}
      table={EmpresaTable}
      form={EmpresaForm}
      label="Empresa"
      renderValue={(value) => {
        return `${value.nome} (${value.documento})`
      }}
      value={value}
      {...rest}
    />
  )
}
