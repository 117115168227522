import {
  CircularProgress,
  CircularProgressProps,
  LinearProgress,
  LinearProgressProps,
  Stack,
} from '@mui/material'

export interface LoadingProps {
  isLoading?: boolean
  isLinear?: boolean
  progressProps?: LinearProgressProps | CircularProgressProps
}

export default function Loading(props: LoadingProps) {
  const { isLoading, isLinear, progressProps } = props
  if (!isLoading) return null

  return (
    <Stack
      sx={{
        position: 'absolute',
        zIndex: '99',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgb(0 0 0 / 50%)',
      }}
    >
      {isLinear ? (
        <LinearProgress {...progressProps} />
      ) : (
        <CircularProgress {...(progressProps as CircularProgressProps)} />
      )}
    </Stack>
  )
}
