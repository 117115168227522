import { Content, PageTitle } from '@data-c/ui'
import { Container, Tab, Tabs } from '@mui/material'
import Loading from 'components/Displays/Loading'
import TabPanel from 'components/TabPanel'
import useContratos, { ContratoModel } from 'hooks/queries/useContratos'
import useTabs from 'hooks/useTabs'
import { useNavigate, useParams } from 'react-router-dom'
import TabEditor from './components/TabEditor'

export default function Contrato() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { useQueryByUuid } = useContratos()
  const {
    data,
    isLoading,
    // error
  } = useQueryByUuid<ContratoModel>(id as string)
  const { handleChange, value } = useTabs()
  if (isLoading)
    return (
      <Container>
        <Loading />
      </Container>
    )

  return (
    <Container>
      <PageTitle title={data?.usuario?.name || ''} onBack={() => navigate(-1)}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs produtos">
          <Tab label="Plano" value={0} />
        </Tabs>
      </PageTitle>
      <Content>
        <TabPanel value={value} index={0}>
          <TabEditor contrato={data} />
        </TabPanel>
      </Content>
    </Container>
  )
}
