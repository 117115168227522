import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function TabAplicativos() {
  const { role, changeRolePermission } = useRoleContext()
  const { aplicativos, permissoes } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Ver"
          description={
            <div>Define se o usuário possui permissão para ver aplicativos</div>
          }
          component={
            <Switch
              name="aplicativos.read"
              checked={aplicativos?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Criar"
          description={
            <div>
              Define se o usuário possui permissão para criar aplicativos
            </div>
          }
          component={
            <Switch
              name="aplicativos.create"
              checked={aplicativos?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Alterar"
          description={
            <div>
              Define se o usuário possui permissão para alterar aplicativos
            </div>
          }
          component={
            <Switch
              name="aplicativos.update"
              checked={aplicativos?.update || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Deletar"
          description={
            <div>
              Define se o usuário possui permissão para deletar aplicativos
            </div>
          }
          component={
            <Switch
              name="aplicativos.delete"
              checked={aplicativos?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Acessar aplicativos protegidos"
          description="Define se o usuário tem permissão para acessar os aplicativos protegidos"
          component={
            <Switch
              name="aplicativos.protegidos"
              checked={Boolean(aplicativos?.protegidos)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa) || !Boolean(permissoes?.protegidas)}
            />
          }
        />
      </Stack>
    </Box>
  )
}
