import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function TabRoles() {
  const { role, changeRolePermission } = useRoleContext()
  const { permissoes } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Ver"
          description={
            <div>Define se o usuário possui permissão para ver permissões</div>
          }
          component={
            <Switch
              name="permissoes.read"
              checked={permissoes?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Criar"
          description={
            <div>
              Define se o usuário possui permissão para criar permissões
            </div>
          }
          component={
            <Switch
              name="permissoes.create"
              checked={permissoes?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Alterar"
          description={
            <div>
              Define se o usuário possui permissão para alterar permissões
            </div>
          }
          component={
            <Switch
              name="permissoes.update"
              checked={permissoes?.update || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Deletar"
          description={
            <div>
              Define se o usuário possui permissão para deletar permissões
            </div>
          }
          component={
            <Switch
              name="permissoes.delete"
              checked={permissoes?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />

        <CardConfig
          title="Acessar permissões protegidas"
          description="Define se o usuário tem permissão para acessar as permissões protegidas"
          component={
            <Switch
              name="permissoes.protegidas"
              checked={Boolean(permissoes?.protegidas)}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa) || !Boolean(permissoes?.protegidas)}
            />
          }
        />
      </Stack>
    </Box>
  )
}
