import useCrud, { EntityInterface } from 'hooks/useCrud'
import { AxiosError } from 'axios'
import { UsuariosModel } from './useUsuarios'
import { AplicativosModel } from './useAplicativos'
import { EmpresasModel } from './useEmpresas'

export interface ContratoModel extends EntityInterface {
  uuid?: string
  usuarioUuid: string | null
  usuario?: UsuariosModel | null
  aplicativoUuid: string | null
  aplicativo?: AplicativosModel | null
  empresaUuid?: string | null
  empresa?: EmpresasModel | null
  planoContratado: any
  ativo: boolean
  validade?: string | null
  valorContrato?: number
  log?: any
}

export default function useContratos() {
  return useCrud<ContratoModel, AxiosError>('/v2/contratos', 'Contrato', 'male')
}
