import { Stack } from '@mui/material'

// import appLogo from 'assets/images/logoTopo.png'
import appLogo from 'assets/images/logov2.png'
import smallAppLogo from 'assets/images/miniLogo.png'
// import { useMemo } from 'react'

interface LogoProps {
  smallLogo?: boolean
}

export default function Logo(props: LogoProps) {
  const { smallLogo } = props

  return (
    <Stack spacing={0} alignItems="center" p={1}>
      <img
        src={!smallLogo ? appLogo : smallAppLogo}
        alt="App Logo Força de Vendas"
      />
    </Stack>
  )
}
