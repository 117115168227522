import useCrud, { EntityInterface } from 'hooks/useCrud'
import { AxiosError } from 'axios'

export interface AplicativosModel extends EntityInterface {
  identificador: string
  nome: string
  configs: any
  descricao: string
  url: string
  ativo: boolean
  habilitaEmpresa: boolean
  protegido: boolean
}

export default function useAplicativos() {
  return useCrud<AplicativosModel, AxiosError>(
    '/v2/aplicativos',
    'Aplicativo',
    'male',
  )
}
