import {
  UsuarioModel,
  obterUsuarioLogado,
} from 'hooks/queries/useUsuarioLogado'
import React, { useContext, useState } from 'react'

const DEFAULT_VALUE = {
  user: {} as UsuarioModel,
  isLoading: false,
  setUser: async () => Promise,
  setLoading: async () => Promise,
  loadUser: async () => {},
  temPermissao: (_: string) => {},
  isDatoon: (_: string) => false,
}

export interface UserProviderProps {
  isLoading: boolean
  user: UsuarioModel | null
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setUser: React.Dispatch<React.SetStateAction<UsuarioModel | null>>
  loadUser: () => Promise<void>
  temPermissao: (_: string) => any
  isDatoon: (email: string) => boolean
}

export const UserContext = React.createContext<UserProviderProps>(DEFAULT_VALUE)

export default function UserProvider(props: any) {
  const { children } = props
  const [user, setUser] = useState<UsuarioModel | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  function temPermissao(roleKey: string) {
    const name = roleKey.split('.')
    const n1 = name[0]
    const n2 = name[1]
    let permissoes = user?.role?.permissoes

    if (
      permissoes &&
      permissoes.hasOwnProperty(n1) &&
      permissoes[n1].hasOwnProperty(n2)
    ) {
      return permissoes[n1][n2]
    }

    return false
  }

  async function loadUser() {
    try {
      setLoading(true)
      const response = await obterUsuarioLogado()
      setUser(response)
    } catch (err) {
      console.log('ERROU', JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  function isDatoon(email: string): boolean {
    if (email) return email.endsWith('@datac.com.br')

    return false
  }

  return (
    <UserContext.Provider
      value={{
        isLoading,
        setLoading,
        user,
        setUser,
        temPermissao,
        loadUser,
        isDatoon,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
export function useUserContext() {
  return useContext<UserProviderProps>(UserContext)
}
