import { Box, Stack, Switch } from '@mui/material'
import CardConfig from 'components/CardConfig'
import { useRoleContext } from 'components/Contexts/RoleContext'

export default function TabUsuarios() {
  const { role, changeRolePermission } = useRoleContext()
  const { usuarios } = role?.permissoes || {}

  return (
    <Box sx={{ mt: 2 }}>
      <Stack spacing={2}>
        <CardConfig
          title="Ver"
          description={
            <div>Define se o usuário possui permissão para ver usuários</div>
          }
          component={
            <Switch
              name="usuarios.read"
              checked={usuarios?.read || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Criar"
          description={
            <div>Define se o usuário possui permissão para criar usuários</div>
          }
          component={
            <Switch
              name="usuarios.create"
              checked={usuarios?.create || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Alterar"
          description={
            <div>
              Define se o usuário possui permissão para alterar usuários
            </div>
          }
          component={
            <Switch
              name="usuarios.update"
              checked={usuarios?.update || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Deletar"
          description={
            <div>
              Define se o usuário possui permissão para deletar usuários
            </div>
          }
          component={
            <Switch
              name="usuarios.delete"
              checked={usuarios?.delete || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
        <CardConfig
          title="Gerenciar Licenças"
          description={
            <div>
              Define se o usuário possui permissão para gerenciar licenças dos
              usuários
            </div>
          }
          component={
            <Switch
              name="usuarios.gerenciar_licenca"
              checked={usuarios?.gerenciar_licenca || ''}
              onChange={changeRolePermission}
              disabled={Boolean(role?.fixa)}
            />
          }
        />
      </Stack>
    </Box>
  )
}
