import { Box, BoxProps, useTheme } from '@mui/material'

export interface ContentProps extends BoxProps {}
export default function Content(props: ContentProps) {
  const theme = useTheme()
  return (
    <Box
      m={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
      }}
      {...props}
    />
  )
}
