import { ReactNode, useState } from 'react'

import { Box, Drawer as MuiDrawer, Toolbar, useTheme } from '@mui/material'
import { CSSObject, styled, Theme } from '@mui/material/styles'

import FullMenu from './componetns/FullMenu'
import MinMenu from './componetns/MinMenu'

import ToogleMenuButton from './componetns/ToogleMenuButton'
import { Button } from '@data-c/ui'

const drawerWidth = 260

export interface MenuItemProps {
  id: string
  label: string
  items?: MenuItemProps[]
  link?: string
  icon?: ReactNode
  visible: boolean
  isNew?: boolean
}

export interface MenuProps {
  menus: MenuItemProps[]
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  background: '#FFFFFF',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  background: '#FFFFFF',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  boxShadow: '2px 3px 20px -16px rgb(32 32 36 / 30%)',
  borderRightStyle: 'none',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function Menu(props: MenuProps) {
  const { menus } = props
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(true)

  function handleToogleDrawerMenu() {
    setOpen(!open)
  }

  return (
    <Drawer variant="permanent" open={open} onClose={handleToogleDrawerMenu}>
      <Toolbar />
      {/* <Box sx={{ height: theme.mixins.toolbar }}>
        {open ? <Logo /> : <Logo smallLogo />}
      </Box> */}

      <ToogleMenuButton
        open={open}
        onToogleDrawerMenu={handleToogleDrawerMenu}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        {open && <FullMenu menus={menus} />}
        {!open && <MinMenu menus={menus} />}

        <Box
          sx={{
            background: theme.palette.primary.main,
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{ color: '#fff', width: '100%' }}
            variant="text"
            onClick={(e) => {
              e.preventDefault()
              window.open(
                'https://datacsistemashelp.zendesk.com/hc/pt-br/articles/28903380435092-Nota-de-Vers%C3%A3o-For%C3%A7a-de-Vendas-1-0-0',
                '_blank',
                'noreferrer',
              )
            }}
          >
            1.0.0
          </Button>
        </Box>
      </Box>
    </Drawer>
  )
}
