import { useFormApi } from '@data-c/providers'
import { DeleteContainer } from '@data-c/ui'

interface DeleteProps {
  onDelete: (v: any) => Promise<void>
}

export default function Delete(props: DeleteProps) {
  const { toggleSubmit, closeConfirm } = useFormApi()
  const { onDelete } = props

  async function handleDelete(value: any) {
    toggleSubmit(true)
    try {
      await onDelete(value)
      closeConfirm()
    } finally {
      toggleSubmit(false)
    }
  }

  return <DeleteContainer onDelete={handleDelete} />
}
