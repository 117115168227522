import { useFormApi, useFormData } from '@data-c/providers'
import { FormContainer } from '@data-c/ui'
import { Grid, TextField } from '@mui/material'
import useRole, { RoleModel } from 'hooks/queries/useRole'
import * as yup from 'yup'
import useValidations from 'hooks/useValidations'

const schema = yup.object().shape({
  nome: yup.string().required('Informe o nome'),
})

export default function Form() {
  const { onChangeFormValue, closeForm, toggleSubmit } = useFormApi<RoleModel>()
  const { setValidationErrors, validationProps } = useValidations()
  const { formValues: data } = useFormData<RoleModel>()
  const { useSubmit } = useRole()
  const { mutateAsync } = useSubmit()

  function handleSubmitForm() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        await mutateAsync(data)
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => toggleSubmit(false))
  }

  return (
    <FormContainer
      dialogProps={{ title: 'Cadastro de Permissão' }}
      onSubmitForm={handleSubmitForm}
    >
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="nome"
            label="Nome"
            value={data?.nome || ''}
            onChange={onChangeFormValue}
            {...validationProps('nome')}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            name="descricao"
            label="Descrição"
            value={data?.descricao || ''}
            onChange={onChangeFormValue}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}
