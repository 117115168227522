import useRole, { RoleModel } from 'hooks/queries/useRole'
import React, { useContext, useState, useRef } from 'react'
import _ from 'lodash'

const DEFAULT_VALUE = {
  isLoading: false,
  role: {} as RoleModel,
  setLoading: () => {},
  setRole: () => {},
  changeRolePermission: (_: React.ChangeEvent<HTMLInputElement>) => {},
}

export interface RoleProviderProps {
  isSubmitting?: boolean
  isLoading?: boolean
  role: RoleModel | null
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setRole: React.Dispatch<React.SetStateAction<RoleModel | null>>
  changeRolePermission: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const RoleContext = React.createContext<RoleProviderProps>(DEFAULT_VALUE)

export default function RoleProvider(props: {
  children: React.ReactNode
  role: RoleModel
}) {
  const { children, role: _role } = props

  const [role, setRole] = useState<RoleModel | null>(_role)
  const [isLoading, setLoading] = useState<boolean>(false)
  const { useSubmit } = useRole()
  const { mutateAsync, isLoading: isSubmitting } = useSubmit()

  function changeRolePermission(e: React.ChangeEvent<HTMLInputElement>) {
    const type = e.target.type || 'text'
    const name = e.target.name.split('.')
    let value = type === 'text' ? e.target.value : e.target.checked

    let permissoes = role?.permissoes
    if (!permissoes) {
      permissoes = {}
    }
    permissoes[name[0]] = { ...permissoes[name[0]], [name[1]]: value }
    permissoes = { ...permissoes }
    setRole({ ...role, permissoes })
    salvar({ ...role, permissoes })
  }

  const debounced = _.debounce((r) => {
    if (r) {
      mutateAsync(r)
    }
  }, 1000)

  const debouncedSave = useRef(debounced)

  function salvar(r: RoleModel) {
    debouncedSave.current(r)
  }

  return (
    <RoleContext.Provider
      value={{
        isLoading,
        setLoading,
        role,
        setRole,
        changeRolePermission,
        isSubmitting,
      }}
    >
      {children}
    </RoleContext.Provider>
  )
}
export function useRoleContext() {
  return useContext<RoleProviderProps>(RoleContext)
}
