import { MioAuth } from '@data-c/ui'
import credentialsConfig from 'configs/credentials'
import useNotification from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const notification = useNotification()
  const navigate = useNavigate()

  function handleLoginSuccess() {
    notification.notifySuccess('Seja bem vindo')
    navigate('/usuarios')
  }

  function handleLoginFailed(err: any) {
    notification.notifyException(err)
  }

  return (
    <MioAuth
      appName="Central de Contas"
      credentialsConfig={credentialsConfig}
      onLoginSuccess={handleLoginSuccess}
      onLoginFailed={handleLoginFailed}
    />
  )
}
